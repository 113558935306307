<template>
  <div>
    <p>Zendesk SSO</p>
  </div>
</template>

<script>
import axios from "axios";
import {API_URL} from "@/const";
import {uuid} from "vue-uuid";

export default {
  name: "sso",
  mounted() {
    axios
      .get(API_URL.PROFILE.PROFILE)
      .then((res) => {
        const payload = {
          iat: (new Date().getTime() / 1000),
          jti: uuid.v4(),
          name: res.data.name,
          email: res.data.mail,
        };

        const jwt = require('jwt-simple');
        const token = jwt.encode(payload, process.env.VUE_APP_ZENDESK_SHARED_KEY);
        let redirect = `https://${process.env.VUE_APP_ZENDESK_SUBDOMAIN}.zendesk.com/access/jwt?jwt=${token}`;

        const return_to = this.$route.query.return_to;
        if (return_to) {
          redirect += `&return_to=${encodeURIComponent(String(return_to))}`;
        }

        window.location = redirect;
      })
      .catch((err) => {
        console.error(err);
      });
  },
};
</script>